<template>
  <div>
    <!-- {{ statusList }} -->

    <h4 class="mr-sm-4 mb-3 header-tablepage">
      {{ form.code }} - {{ form.name }}
    </h4>
    <b-tabs content-class="py-3" v-model="tabIndex">
      <b-tab
        v-for="status of statusList"
        :key="status.id"
        :value="status.id"
        @click="onTabChange(status.id, $event)"
      >
        <template #title>
          {{ status.name }}
          <b-badge pill>
            {{ status.count }}
            <span class="sr-only">count items list</span></b-badge
          >
        </template>
        <div class="d-flex justify-content-between">
          <InputText
            v-model="filter.search"
            type="text"
            faIcon="search"
            className="mb-2 bg-second w-50"
            placeholder="ค้นหาลูกค้า"
            @onClickIcon="onSearch"
            textField="telephone"
            @onEnter="onSearch"
          />
          <b-button
            class="bg-main bt-height"
            v-if="form.can_create"
            :disabled="isLoading"
            @click="goDetail(0)"
            ><font-awesome-icon icon="plus" class="mr-0 mr-sm-1" />
            สร้าง
          </b-button>
        </div>
        <b-table
          striped
          hover
          :busy="isBusy"
          :items="documentList"
          :fields="fields"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(document_no)="data">
            {{ data.item.document_no || "-" }}
          </template>
          <template v-slot:cell(remark)="data">
            {{ data.item.remark || "-" }}
          </template>
          <template v-slot:cell(created_time)="data">
            <div class="text-center text-black my-2">
              {{ $moment(data.item.created_time).format("DD/MM/YYYY") }}
              <br />
              {{ $moment(data.item.created_time).format("HH:mm") }}
            </div>
          </template>
          <template v-slot:cell(source_branch.branch_name)="data">
            <div>{{ data.item.source_branch.branch_name }}</div>
            <!-- <br /> -->
            <div>รหัส : {{ data.item.source_branch.branch_id }}</div>
          </template>
          <template v-slot:cell(destination_branch.branch_name)="data">
            <div>{{ data.item.destination_branch.branch_name }}</div>
            <!-- <br /> -->
            <div>รหัส : {{ data.item.destination_branch.branch_id }}</div>
          </template>
          <template v-slot:cell(action)="data">
            <div class="d-flex align-items-center justify-content-center">
              <font-awesome-icon
                @click="toggleDetail(data)"
                icon="clipboard"
                title="detail"
                class="mr-2 pointer"
              />
              <font-awesome-icon
                icon="pencil-alt"
                title="detail"
                class="ml-2 pointer"
                @click="goDetail(data.item.id)"
              />
              <b-dropdown no-caret ref="dropdown" class="" variant="link" right>
                <template #button-content>
                  <font-awesome-icon
                    icon="ellipsis-v"
                    title="detail"
                    class="text-black pointer"
                  />
                </template>

                <b-dropdown-item
                  v-if="data.item.cancellable"
                  @click="confirmModal(1, data.item.id)"
                >
                  ยกเลิก
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.confirm_transfer"
                  @click="confirmModal(2, data.item.id)"
                >
                  ยืนยันจัดส่ง
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.confirm_receive"
                  @click="confirmModal(3, data.item.id)"
                  >ยืนยันรับของ</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="data.item.ready_to_ship"
                  @click="confirmModal(4, data.item)"
                  >ยืนยันการจัดของ</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="data.item.exportable"
                  @click="exportDetail(data)"
                >
                  Export To Excel
                </b-dropdown-item>
                <b-dropdown-item
                  @click="goDetail(`${data.item.id}?tabIndex=1`)"
                >
                  ประวัติ
                </b-dropdown-item>
                <!-- </template> -->
              </b-dropdown>
            </div>
          </template>
          <template #row-details="row">
            <b-table
              striped
              hover
              :busy="row.item.isBusy"
              :items="row.item.row_detail"
              :fields="fieldsDetail"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(product_name)="data">
                <div>
                  {{ data.item.display_name }}

                  <template v-if="data.item.is_serial"
                    >({{ data.item.serial_name }})</template
                  >
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </b-tab>
    </b-tabs>
    <ModalAlertConfirm
      ref="modalAlertConfirm"
      :msg="modalMessage"
      text=""
      @confirm="modalCallBack"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import InputText from "@/components/inputs/InputText";
import ModalAlertConfirm from "./components/ModalAlertNote";
export default {
  components: {
    Pagination,
    InputText,
    ModalAlertConfirm,
  },
  data() {
    return {
      filter: {
        search: "",
        inventory_transfer_document_id: this.$route.params.id,
        inventory_transfer_status: [],
        page: 1,
        take: 10,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      rows: 0,
      statusList: [],
      documentList: [],
      fields: [
        { label: "วันที่", key: "created_time" },
        { label: "เลขที่เอกสาร", key: "document_no" },
        { label: "สถานะ", key: "status_name" },
        {
          label: "ต้นทาง",
          key: "source_branch.branch_name",
          class: "text-left",
        },
        {
          label: "ปลายทาง",
          key: "destination_branch.branch_name",
          class: "text-left",
        },
        { label: "หมายเหตุ", key: "remark" },
        { label: "", key: "action" },
      ],
      tabIndex: 1,
      isBusy: false,
      isLoading: false,
      fieldsDetail: [
        { label: "#", key: "" },
        { label: "Product", key: "product_name" },
        { label: "Quantity", key: "quantity" },
      ],
      modalCallBack: () => {},
      modalMessage: "",
      form: {},
    };
  },
  watch: {
    tabIndex(val) {},
  },
  created() {
    this.getStatusList();
    this.getDetail();
  },
  methods: {
    async getData() {
      this.isBusy = true;
      //   this.filter.inventory_transfer_status = [val];
      this.filter.inventory_transfer_status =
        this.filter.inventory_transfer_status == 4
          ? []
          : this.filter.inventory_transfer_status;
      this.documentList = [];
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/List`,
        this.filter
      );

      this.rows = res.detail.total_count;
      this.documentList = res.detail.transfer_list.map((el) => {
        return { ...el, row_detail: [], isBusy: false };
      });
      this.isBusy = false;
    },
    async getDetail() {
      const res = await this.axios(
        `${this.$baseUrl}/Inventory/Document/${this.$route.params.id}`
      );
      this.form = res.detail;
    },
    async getStatusList(flag = true) {
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/ListStatus`,
        this.filter
      );
      if (flag) this.filter.inventory_transfer_status = [res.detail[0].id];
      this.getData();
      this.statusList = res.detail;
    },

    handleChangeTake(val) {
      this.filter.take = val;
      this.filter.page = 1;
      this.getData();
    },
    pagination(val) {
      this.filter.page = val;
      this.getData();
    },
    onSearch() {
      this.getData();
    },
    onTabChange(val, e) {
      this.filter.inventory_transfer_status = val == 4 ? [] : [val];
      this.getData(val);
    },
    goDetail(id) {
      this.$router.push(
        `/inventory-transfer/${this.$route.params.id}/detail/${id}`
      );
    },
    async toggleDetail(row) {
      row.item.isBusy = true;
      await this.$nextTick();

      row.toggleDetails();
      if (row.item.row_detail.length == 0) this.getRowDetail(row.item);
      else row.item.isBusy = false;
    },
    async getRowDetail(row) {
      const res = await this.axios(
        `${this.$baseUrl}/Inventory/Transfer/${row.id}/Product`,
        this.filter
      );
      row.row_detail = res.detail;
      row.isBusy = false;
      await this.$nextTick();
    },
    confirmModal(type, id) {
      this.$refs.modalAlertConfirm.show();

      switch (type) {
        case 1:
          this.modalMessage = "ยืนยันยกเลิกรายการ";
          this.modalCallBack = (note) => this.cancel(id, note);
          break;
        case 2:
          this.modalMessage = "ยืนยันการจัดส่ง";
          this.modalCallBack = (note) => this.confirmTransfer(id, note);
          break;
        case 3:
          this.modalMessage = "ยืนยันการรับของ";
          this.modalCallBack = (note) => this.confirmRecieve(id, note);
          break;
        case 4:
          this.modalMessage = "ยืนยันการจัดของ";
          this.modalCallBack = (note) => this.confirmOrder(id, note);
          break;
      }
    },
    async cancel(id, note) {
      await this.$store.dispatch("setMainLoading", true);
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/Cancel`,
        {
          inventory_transfer_id: id,
          remark: note,
        }
      );
      if (res.result == 1) this.successAlert(), this.getStatusList();
      else this.errorAlert(res.message);
      await this.$store.dispatch("setMainLoading", false);
    },
    async confirmTransfer(id, note) {
      await this.$store.dispatch("setMainLoading", true);
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/ConfirmTransfer`,
        {
          inventory_transfer_id: id,
          remark: note,
        }
      );
      if (res.result == 1) this.successAlert(), this.getStatusList();
      else this.errorAlert(res.message);
      await this.$store.dispatch("setMainLoading", false);
    },
    async confirmRecieve(id, note) {
      await this.$store.dispatch("setMainLoading", true);
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/ConfirmReceive`,
        {
          inventory_transfer_id: id,
          remark: note,
        }
      );
      if (res.result == 1) this.successAlert(), this.getStatusList();
      else this.errorAlert(res.message);
      await this.$store.dispatch("setMainLoading", false);
    },
    async exportDetail(items) {
      await this.$store.dispatch("setMainLoading", true);
      const res = await this.axios(
        `${this.$baseUrl}/Inventory/Transfer/${items.item.id}/export`,

        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `${items.item.document_no || "Draft"}-${this.$moment().format(
          "DD/MM/YYYY_HH:MM:ss"
        )}(${items.item.status_name}).xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      await this.$store.dispatch("setMainLoading", false);
    },
    async confirmOrder(data, note) {
      await this.$store.dispatch("setMainLoading", true);
      let payload = {
        id: data.id,
        is_ready_to_ship: true,
        remark: note,
        serial_products: [],
      };

      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/SavePendingPacking`,
        payload
      );

      if (res.result == 1) this.successAlert(), this.getStatusList();
      else this.errorAlert(res.message);
      await this.$store.dispatch("setMainLoading", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.bt-height {
  height: 35px !important;
}
.header-tablepage {
  color: var(--primary-color) !important;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}
</style>
