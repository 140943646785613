<template>
  <div>
    <b-sidebar
      v-model="showModal"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      aria-labelledby="sidebar-customer-List-title"
      @hidden="hide()"
      no-header
      :lazy="true"
      id="sidebar-discount"
    >
      <template>
        <div class="sidebar-content" id="sidebar-customer-List">
          <div class="filter">
            <div class="d-flex justify-content-between align-items-center p-3">
              <label class="header-text">เพิ่มคูปองและส่วนลดท้ายบิล</label>
              <div class="pointer" @click="hide()">
                <b-icon icon="x-circle-fill"></b-icon>
              </div>
            </div>

            <div>
              <b-tabs v-model="indexEdit" fill>
                <b-tab>
                  <template #title>
                    <div class="text-m-14">คูปอง</div>
                    <div class="text-m-14">
                      {{ `(${displayPromotionList.length || 0})` }}
                    </div>
                  </template>
                </b-tab>
                <b-tab v-if="isCampaign">
                  <template #title>
                    <div class="text-m-14">ส่วนลดท้ายบิล</div>
                    <div class="text-m-14">
                      {{ `(${items.length})` }}
                    </div>
                  </template>
                </b-tab>
                <b-tab v-if="!isCampaign">
                  <template #title>
                    <div class="text-m-14">ส่วนลดรายการ</div>
                    <div class="text-m-14">
                      {{
                        `(${
                          promotionListformProduct[selectProductIndex].length +
                            itemDiscountFromSelect[selectProductIndex].length ||
                          0
                        })`
                      }}
                    </div>
                  </template>
                </b-tab>
              </b-tabs>
            </div>

            <div
              class="d-flex justify-content-start align-items-end px-3 py-2 filter"
            >
              <InputText
                v-model="filter.search"
                textFloat=""
                type="text"
                name="search_customer"
                faIcon="search"
                className="mb-2 w-100"
                @onEnter="handleSearch"
                @onClickIcon="handleSearch"
              />
              <div>
                <img
                  src="@/assets/images/icons/bin.png"
                  alt="delete"
                  class="pointer delete-icon"
                  center
                  width="32"
                  @click="clearSearch()"
                />
              </div>
            </div>
          </div>

          <div class="customer-list pb-3">
            <div v-if="isLoading" class="loading-panel">
              <OtherLoading />
            </div>
            <template v-else-if="indexEdit == 0 && !isLoading">
              <div
                class="p-2 my-2 bg-white pointer customer-item"
                v-for="(promotion, index) in displayPromotionList"
                :key="index"
                @click="selectRow('addCoupon', promotion)"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="text-m-16">{{ promotion.name }}</p>

                    <p v-if="promotion.code" class="text-m-14">
                      {{ promotion.code }}
                    </p>
                  </div>
                  <div class="flex-gap-1">
                    <span
                      v-if="
                        promotion.point >= 0 && promotion.promotion_value == 0
                      "
                      class="text-m-16"
                    >
                      ได้รับ {{ promotion.point | numeral("0,0") }}
                    </span>
                    <span
                      v-else-if="promotion.custom_header_discount"
                      class="text-m-16"
                    >
                      {{ promotion.custom_header_discount > 0 ? "-" : ""
                      }}{{
                        promotion.custom_header_discount | numeral("0,0")
                      }}</span
                    >

                    <span v-else class="text-m-16"
                      >ส่วนลด
                      {{ `${promotion.promotion_value}` | numeral("0,0") }}
                    </span>

                    <span
                      class="text-m-16 text-right"
                      :class="{
                        '':
                          !(
                            promotion.point >= 0 &&
                            promotion.promotion_value == 0
                          ) &&
                          promotion.discount_type !== 'point' &&
                          promotion.promotion_discount_type_id !== 3,
                      }"
                    >
                      {{
                        promotion.point >= 0 && promotion.promotion_value == 0
                          ? "แต้ม"
                          : promotion.promotion_discount_type_id == 1 ||
                            promotion.discount_type == "percent"
                          ? "%"
                          : promotion.promotion_discount_type_id == 2 ||
                            promotion.discount_type == "amount"
                          ? "บาท"
                          : promotion.promotion_discount_type_id == 3 ||
                            promotion.discount_type == "point"
                          ? "แต้ม"
                          : ""
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="promotion-point text-right text-m-14 mt-1"
                  v-if="promotion.required_point > 0"
                >
                  ใช้แต้ม {{ promotion.required_point }} P
                </div>
              </div>
            </template>
            <template v-if="!isCampaign && indexEdit == 1 && !isLoading">
              <!-- ส่วนลดรายการ -->
              <div
                class="p-2 my-2 bg-white pointer customer-item"
                v-for="(promotion, index) in displayItemDiscountList[
                  selectProductIndex
                ]"
                :key="promotion.id"
              >
                <b-form-checkbox
                  v-model="itemDiscountSelected"
                  :value="promotion"
                  @change="
                    selectRow('addItemDiscount', promotion, selectProductIndex)
                  "
                  class="w-100"
                >
                  <div class="d-flex justify-content-between custom-label">
                    <div>
                      <p class="text-m-16">{{ promotion.name }}</p>
                    </div>

                    <div class="flex-gap-1">
                      <span
                        v-if="
                          promotion.point >= 0 && promotion.promotion_value == 0
                        "
                        class="text-m-16"
                      >
                        ได้รับ {{ promotion.point | numeral("0,0") }}</span
                      >

                      <span v-else class="text-m-16"
                        >{{ promotion.promotion_value > 0 ? "ส่วนลด " : ""
                        }}{{
                          `${promotion.promotion_value}` | numeral("0,0.00")
                        }}</span
                      >

                      <span
                        class="text-m-16"
                        :class="{
                          '':
                            !(
                              promotion.point >= 0 &&
                              promotion.promotion_value == 0
                            ) &&
                            promotion.discount_type !== 'point' &&
                            promotion.promotion_discount_type_id !== 3,
                        }"
                      >
                        {{
                          promotion.point >= 0 && promotion.promotion_value == 0
                            ? "แต้ม"
                            : promotion.promotion_discount_type_id == 1 ||
                              promotion.discount_type == "percent"
                            ? "%"
                            : promotion.promotion_discount_type_id == 2 ||
                              promotion.discount_type == "amount"
                            ? "บาท"
                            : promotion.promotion_discount_type_id == 3 ||
                              promotion.discount_type == "point"
                            ? "แต้ม"
                            : ""
                        }}
                      </span>
                    </div>
                  </div>

                  <div
                    class="promotion-point text-right text-m-14 mt-1"
                    v-if="promotion.required_point > 0"
                  >
                    ใช้แต้ม {{ promotion.required_point }} P
                  </div>
                </b-form-checkbox>
              </div>
              <!-- ส่วนลดในสินค้านั้นๆ -->
              <div
                class="p-2 my-2 bg-white pointer customer-item"
                v-for="(promotion, index) in displayPromotionListformProduct[
                  selectProductIndex
                ]"
                :key="index"
              >
                <b-form-checkbox
                  v-model="sapSelected"
                  :value="promotion"
                  @change="
                    selectRow('addSapDiscount', promotion, selectProductIndex)
                  "
                >
                  <div class="d-flex justify-content-between custom-label">
                    <div>
                      <p class="text-m-16">{{ promotion.name }}</p>
                    </div>

                    <div class="flex-gap-1">
                      <span
                        v-if="
                          promotion.point >= 0 && promotion.promotion_value == 0
                        "
                        class="text-m-16"
                      >
                        ได้รับ {{ promotion.point | numeral("0,0") }}</span
                      >

                      <span v-else class="text-m-16"
                        >{{ promotion.promotion_value > 0 ? "ส่วนลด " : ""
                        }}{{
                          `${promotion.promotion_value}` | numeral("0,0.00")
                        }}</span
                      >

                      <span
                        class="text-m-16"
                        :class="{
                          '':
                            !(
                              promotion.point >= 0 &&
                              promotion.promotion_value == 0
                            ) &&
                            promotion.discount_type !== 'point' &&
                            promotion.promotion_discount_type_id !== 3,
                        }"
                      >
                        {{
                          promotion.point >= 0 && promotion.promotion_value == 0
                            ? "แต้ม"
                            : promotion.promotion_discount_type_id == 1 ||
                              promotion.discount_type == "percent"
                            ? "%"
                            : promotion.promotion_discount_type_id == 2 ||
                              promotion.discount_type == "amount"
                            ? "บาท"
                            : promotion.promotion_discount_type_id == 3 ||
                              promotion.discount_type == "point"
                            ? "แต้ม"
                            : ""
                        }}
                      </span>
                    </div>
                  </div>

                  <div
                    class="promotion-point text-right text-m-14 mt-1"
                    v-if="promotion.required_point > 0"
                  >
                    ใช้แต้ม {{ promotion.required_point }} P
                  </div>
                </b-form-checkbox>
              </div>
            </template>
            <template v-if="isCampaign && indexEdit == 1 && !isLoading">
              <div
                class="p-2 my-2 bg-white pointer customer-item"
                v-for="(promotion, index) in items"
                :key="index"
                @click="selectRow('addCoupon', promotion)"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="text-m-16">{{ promotion.name }}</p>

                    <p v-if="promotion.code" class="text-m-14">
                      {{ promotion.code }}
                    </p>
                  </div>
                  <div class="flex-gap-1">
                    <span
                      v-if="
                        promotion.point >= 0 && promotion.promotion_value == 0
                      "
                      class="text-m-16"
                    >
                      ได้รับ {{ promotion.point | numeral("0,0") }}
                    </span>
                    <span
                      v-else-if="promotion.custom_header_discount"
                      class="text-m-16"
                      >ส่วนลด
                      {{ promotion.custom_header_discount > 0 ? "-" : ""
                      }}{{
                        promotion.custom_header_discount | numeral("0,0")
                      }}</span
                    >

                    <span v-else class="text-m-16"
                      >ส่วนลด
                      {{ `${promotion.promotion_value}` | numeral("0,0") }}
                    </span>

                    <span
                      class="text-m-16"
                      :class="{
                        '':
                          !(
                            promotion.point >= 0 &&
                            promotion.promotion_value == 0
                          ) &&
                          promotion.discount_type !== 'point' &&
                          promotion.promotion_discount_type_id !== 3,
                      }"
                    >
                      {{
                        promotion.point >= 0 && promotion.promotion_value == 0
                          ? "แต้ม"
                          : promotion.promotion_discount_type_id == 1 ||
                            promotion.discount_type == "percent"
                          ? "%"
                          : promotion.promotion_discount_type_id == 2 ||
                            promotion.discount_type == "amount"
                          ? "บาท"
                          : promotion.promotion_discount_type_id == 3 ||
                            promotion.discount_type == "point"
                          ? "แต้ม"
                          : ""
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="promotion-point text-right text-m-14 mt-1"
                  v-if="promotion.required_point > 0"
                >
                  ใช้แต้ม {{ promotion.required_point }} P
                </div>
                <div
                  @click.stop.prevent="
                    $emit('adjustDiscount', promotion, 'header')
                  "
                  class="promotion-edit"
                  v-if="promotion.custom_header == 1"
                >
                  ปรับส่วนลด
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";

export default {
  name: "SlideCustomerList",
  components: { OtherLoading, InputText },
  props: {
    headerDiscountOption: {
      type: Array,
      isRequired: true,
    },
    promotionList: {
      type: Array,
      isRequired: true,
    },
    promotionListformProduct: {
      type: Array,
      isRequired: true,
    },
    itemDiscountList: {
      type: Array,
      isRequired: true,
    },
    headerDiscountItems: {
      type: Array,
      isRequired: true,
    },
    isCampaign: {
      type: Boolean,
      isRequired: false,
    },
    selectProductIndex: {
      type: Number,
      isRequired: true,
    },
    itemDiscountFromSelect: {
      types: Array,
      isRequired: false,
    },
    form: {
      types: Array,
      isRequired: false,
    },
  },

  data() {
    return {
      indexEdit: 0,
      isLoading: false,
      showModal: false,
      filter: {
        search: "",
        datetime: "",
        branchId: "",
        page: 1,
        take: 20,
      },
      now: null,
      items: [],
      listWrapper: null,
      rows: 0,
      promotionSelected: [
        {
          item: [],
          sap: [],
        },
      ],
      displayPromotionList: [],
      displayItemDiscountList: [],

      tempPromotionList: [],
      tempItemDiscountList: [],
      tempCouponList: [],

      tempPromotionListformProduct: [],

      itemDiscountSelected: [],
      sapSelected: [],
    };
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
    isLastPage() {
      return this.items.length === this.rows;
    },
  },
  watch: {
    promotionList: {
      handler() {
        this.displayPromotionList = JSON.parse(
          JSON.stringify(this.promotionList)
        );
        this.tempPromotionList = JSON.parse(JSON.stringify(this.promotionList));
      },
      immediate: true,
    },
    promotionListformProduct: {
      handler() {
        this.isLoading = true;
        this.displayPromotionListformProduct = JSON.parse(
          JSON.stringify(this.promotionListformProduct)
        );
        this.tempPromotionListformProduct = JSON.parse(
          JSON.stringify(this.promotionListformProduct)
        );
        this.isLoading = false;
      },
      immediate: true,
    },
    itemDiscountFromSelect: {
      handler() {
        this.isLoading = true;
        this.displayItemDiscountList = this.itemDiscountFromSelect;

        this.tempItemDiscountList = JSON.parse(
          JSON.stringify(this.itemDiscountFromSelect)
        );
        this.isLoading = false;
      },
      immediate: true,
    },
  },
  created: async function () {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
  },
  methods: {
    handleClickOutside() {
      if (this.showModal) {
        this.showModal = false;
      }
    },
    clearSearch() {
      this.filter.search = "";
      this.displayPromotionList = JSON.parse(
        JSON.stringify(this.tempPromotionList)
      );
      this.displayPromotionListformProduct = JSON.parse(
        JSON.stringify(this.tempPromotionListformProduct)
      );

      this.displayItemDiscountList = JSON.parse(
        JSON.stringify(this.tempItemDiscountList)
      );

      this.items = JSON.parse(JSON.stringify(this.tempCouponList));
    },

    async handleSearch() {
      this.isLoading = true;
      try {
        if (this.indexEdit === 0) {
          this.displayPromotionList = this.filter.search
            ? this.tempPromotionList.filter((el) =>
                el.name.toLowerCase().includes(this.filter.search.toLowerCase())
              )
            : JSON.parse(JSON.stringify(this.tempPromotionList));
        } else if (this.isCampaign) {
          this.items = this.filter.search
            ? this.tempCouponList.filter((el) =>
                el.name.toLowerCase().includes(this.filter.search.toLowerCase())
              )
            : JSON.parse(JSON.stringify(this.tempCouponList));
        } else {
          let index = this.selectProductIndex;
          this.displayPromotionListformProduct[index] = this.filter.search
            ? this.tempPromotionListformProduct[index].filter((el) =>
                el.name.toLowerCase().includes(this.filter.search.toLowerCase())
              )
            : JSON.parse(
                JSON.stringify(this.tempPromotionListformProduct[index])
              );

          this.displayItemDiscountList[index] = this.filter.search
            ? this.tempItemDiscountList[index].filter((el) =>
                el.name.toLowerCase().includes(this.filter.search.toLowerCase())
              )
            : JSON.parse(JSON.stringify(this.tempItemDiscountList[index]));
        }
      } finally {
        this.isLoading = false;
      }
    },
    getList: async function () {
      this.isLoading = true;
      let datetime =
        this.dateTimeFix || this.$moment().format("YYYY-MM-DDTHH:mm:ss");

      await this.axios
        .get(
          `${this.$baseUrl}/coupon/get_list/${
            this.user.user_guid || ""
          }/${datetime}/${this.branchId}`
        )
        .then(async (data) => {
          if (data.result == 1) {
            this.items = this.headerDiscountOption;

            this.displayPromotionList = data.detail;

            this.tempPromotionList = JSON.parse(
              JSON.stringify(this.displayPromotionList)
            );
            this.tempCouponList = JSON.parse(JSON.stringify(this.items));

            this.tempPromotionListformProduct = JSON.parse(
              JSON.stringify(this.promotionListformProduct)
            );

            this.displayPromotionListformProduct = JSON.parse(
              JSON.stringify(this.promotionListformProduct)
            );

            this.tempItemDiscountList = JSON.parse(
              JSON.stringify(this.itemDiscountFromSelect)
            );

            this.displayItemDiscountList = JSON.parse(
              JSON.stringify(this.itemDiscountFromSelect)
            );

            this.rows = data.total_count;
            this.sapSelected = JSON.parse(
              JSON.stringify(this.form[this.selectProductIndex].sap_discount)
            );

            this.itemDiscountSelected = JSON.parse(
              JSON.stringify(this.form[this.selectProductIndex].item_discount)
            );
          }
        });

      this.isLoading = false;
    },
    async show(user, selecedHeaderDiscountIdList) {
      this.user = user;
      this.selectedList = [...selecedHeaderDiscountIdList];
      this.showModal = true;
      this.indexEdit = 0;
      await this.getList();
    },

    hide() {
      this.showModal = false;
      this.clearSearch();
    },

    selectRow(action, item) {
      this.$emit(action, item);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav {
  display: inline-flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;

  .nav-link {
    min-width: 107px !important;
  }
}
.customer-card {
  background-color: white;
  border-radius: 8px;
  p {
    padding: 0px;
  }
}
.customer-card:hover {
  background-color: #d1d1d1;
}

.sidebar-content {
  height: calc(100vh - 9px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 90px;
  .filter {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #ebe5e5;
  }

  .customer-list {
    flex: 1;

    .customer-item {
      border-bottom: 1px solid #ebe5e5;
      min-height: 65px;
    }

    .loading-panel {
      height: 50px;
      div {
        height: 100%;
      }
    }
  }
}

::v-deep .custom-control-label {
  width: 100%;
}

::v-deep .b-sidebar > .b-sidebar-body {
  overflow: hidden;
}

.custom-label {
  margin-top: -1px;
}
.delete-icon {
  margin-bottom: 11px;
  margin-left: 8px;
}

.promotion-point {
  font-size: 12px;
}

::v-deep .b-sidebar {
  width: 90vw;
}
</style>
